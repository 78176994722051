<template>
    <div>
      <h1>Ecommerce App</h1>
      <p>Welcome to your ecommerce demo appp!</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Home',
  };
  </script>
  