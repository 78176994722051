import { createRouter, createWebHistory } from 'vue-router';
import Home from '../components/Home.vue';
import Users from '../components/Users.vue';
import Orders from '../components/Orders.vue';
import SupportTickets from '@/components/SupportTickets.vue';

const routes = [{
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/users',
        name: 'Users',
        component: Users,
    },
    {
        path: '/orders',
        name: 'Orders',
        component: Orders,
    },
    {
        path: '/tickets',
        name: 'Support Tickets',
        component: SupportTickets,
    },
    // Add more routes here
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;