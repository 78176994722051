<template>
  <div id="app">
    <!-- Navigation Bar -->
    <nav>
      <router-link to="/">Home</router-link> | <!-- Link to Home -->
      <router-link to="/users">Users</router-link> | <!-- Link to About -->
      <router-link to="/orders">Orders</router-link> | <!-- Link to Orders -->
      <router-link to="/tickets">Support Tickets</router-link> <!-- Link to Orders -->
    </nav>

    <!-- Page Content -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
/* Global styles */
#app {
  text-align: center;
}

nav a {
  margin: 0 10px;
  text-decoration: none;
  color: #42b983;
}

nav a.router-link-exact-active {
  color: #000;
}
</style>
