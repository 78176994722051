<template>
    <div id="app" class="container">
        <h1 class="my-4">Users</h1>
        
        <div class="row mb-3">
            <div class="col-md-4">
                <div class="input-group">
                    <label for="userCount" class="input-group-text">Number of users to create:</label>
                    <input type="number" id="userCount" class="form-control" v-model="userCount" min="1">
                </div>
            </div>
            <div class="col-md-8">
                <button class="btn btn-primary me-2" @click="generate_user">Generate Users</button>
            </div>
        </div>

        <p class="text-muted">{{ new_user }}</p>
        
        <div v-if="loading" class="text-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        
        <div v-else>
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead class="table-dark">
                        <tr>
                            <th>ID</th>
                            <th>Username</th>
                            <th>Firstname</th>
                            <th>Lastname</th>
                            <th>Date Joined</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in paginatedData" :key="item.user_id">
                            <td>{{ item.user_id }}</td>
                            <td>{{ item.username }}</td>
                            <td>{{ item.first_name }}</td>
                            <td>{{ item.last_name }}</td>
                            <td>{{ item.date_joined }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
            <!-- Pagination Controls -->
            <div class="d-flex justify-content-between align-items-center my-3">
                <button type="button" class="btn btn-secondary" @click="prevPage" :disabled="currentPage === 1">Prev</button>
                <span>Page {{ currentPage }} of {{ totalPages }}</span>
                <button type="button" class="btn btn-secondary" @click="nextPage" :disabled="currentPage === totalPages">Next</button>
            </div>
            
            <!-- Page Size Selector -->
            <div class="form-group">
                <label for="pageSize">Items per page:</label>
                <select id="pageSize" class="form-select" v-model="pageSize" @change="changePageSize">
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                </select>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'App',
    data() {
        return {
            apiResult: [],
            loading: true,
            currentPage: 1,
            pageSize: 10,
        };
    },
    computed: {
        totalPages() {
            return Math.ceil(this.apiResult.length / this.pageSize);
        },
        paginatedData() {
            const start = (this.currentPage - 1) * this.pageSize;
            const end = parseInt(start) + parseInt(this.pageSize);
            console.log("start")
            console.log(start)
            console.log("end")
            console.log(end)
            return  this.apiResult.slice(start, end)
        },
    },
    created() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                const response = await fetch(process.env.VUE_APP_API_URL + '/users');
                const data = await response.json();
                this.apiResult = data; // Keep all fetched data here
            } catch (error) {
                console.error("Error fetching data: ", error);
            } finally {
                this.loading = false;
            }
        },
        async generate_user() {
            this.loading = true;
            this.new_user = ''
            const userDetails = {
                // Example user details - replace with your actual data structure
                quantity: this.userCount,
            };
            try {
                const response = await fetch(process.env.VUE_APP_API_URL + '/users', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(userDetails),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                this.new_user = data.message
                console.log('user created:', data);
                await this.fetchData()
                setTimeout(() => {
                    this.new_user = '';
                }, 5000);
                //set this.new_user to false after 5 seconds 
                

                // Update your component's state or perform actions based on the response
            } catch (error) {
                console.error("Error creating user: ", error);
            } finally {
                
                this.loading = false;
            }
        },
        prevPage() {
            if (this.currentPage > 1) this.currentPage--;
        },
        nextPage() {
            if (this.currentPage < this.totalPages) this.currentPage++;
        },
        changePageSize() {
            this.currentPage = 1; // Reset to first page to avoid being out of range
        },
    }
};

</script>